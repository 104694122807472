import * as React from "react";
import { Link, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useInView } from "react-intersection-observer";
import Container from "../components/container";
import Layout from "../components/layout";
import enjin_2_mp4 from "../assets/enjin_2_test2.mp4";
import enjin_2_webm from "../assets/enjin_2_test2.webm";
import enjin_2_poster from "../assets/enjin_2_test2_poster.jpg";
import { ContactForm } from "../components/contact-form";
import {
  videoBackgroundColorHack5,
  flexGrid,
  sectionVideo,
  sectionText,
  sectionTextNegativeMarginTop,
  sectionPadding,
  containerPaddingLeft,
  containerPaddingRight,
  orderReverse,
  marginTopMobile,
  containerPadding,
  eyesContainer,
  column,
} from "./page.module.scss";

const TheIEngineersPage = ({ data }) => {
  const videoRef = React.useRef(null);
  const [showFirstImage, setShowFirstImage] = React.useState(true);

  const [ref] = useInView({
    triggerOnce: false,
    rootMargin: "0px 0px",
    onChange: (inView) => {
      if (inView) {
        videoRef?.current && videoRef.current.play();
      } else {
        videoRef?.current && videoRef.current.pause();
      }
    },
  });

  React.useEffect(() => {
    const timing = showFirstImage ? 2000 : 500;
    const intervalId = setInterval(() => {
      setShowFirstImage(!showFirstImage);
    }, timing);
    return () => clearInterval(intervalId);
  }, [showFirstImage]);

  return (
    <Layout colorScheme="the-i-engineers" pageTitle="Über uns">
      <main>
        <section
          className={`${"text-rose-pink"} ${"background-orange"}`}
          style={{ position: "relative" }}
          ref={ref}
        >
          <video
            autoPlay
            loop
            muted
            playsInline
            poster={enjin_2_poster}
            className={videoBackgroundColorHack5}
            ref={videoRef}
          >
            <source src={enjin_2_mp4} type="video/mp4" />
            <source src={enjin_2_webm} type="video/webm" />
          </video>
          <Container noPadding={true}>
            <div className={flexGrid}>
              <div className={column}>
                <div className={sectionVideo}></div>
              </div>

              <div
                className={`${column} ${sectionText} ${containerPadding} ${sectionTextNegativeMarginTop}`}
              >
                <h1>When life becomes digital.</h1>
                <p className="text-large">
                  Wir digitalisieren Ihre Klinik und automatisieren Ihre
                  Zufriedenheit.
                </p>
              </div>
            </div>
          </Container>
        </section>

        <section
        id="ueber-uns"
          className={`${"background-rose-pink"} ${"text-orange"} ${sectionPadding}`}
        >
          <Container>
            <div className={flexGrid}>
              <div
                className={`${column} ${sectionText} ${containerPaddingRight}`}
              >
                <p className="text-large">
                Wir sind ein marktführendes Unternehmen aus der Schweiz, 
                entwickeln seit 2002 Softwareprodukte für das Gesundheitswesen und bieten 
                mit der <span className="italic">health-engine® </span>eine bewährte und vielseitige Lösung zur Digitalisierung 
                von Prozessen in Krankenhäusern. Bereits über 300 Kliniken aller Grössen vertrauen unseren Spezialisten in den Bereichen Clinical Data Repository, 
                Interoperabilitätsplattform und Workflow-/Prozessmanagement. 
                </p>
                <p className="text-large">
                Über 100,000 Nutzende der Lösung schätzen neben der Flexibilität und 
                Skalierbarkeit der <span className="italic">health-engine®</span> mit ihren über 40 Modulen, gerade auch 
                die Agilität, Expertise und Kundenorientierung unseres Teams. Wir erreichen regelmässig Rekordergebnisse 
                bei Befragungen zur Kundenzufriedenheit. 
                </p>

                <p className="text-large">
                Am Hauptsitz in Zürich und den deutschen Standorten in Münster, München und Dresden arbeiten Teams aus 
                erfahrenen Mitarbeitenden an einem gemeinsamen Ziel: zufriedene Kunden bei der Digitalisierung ihrer Geschäftsprozesse zu unterstützen.
                </p>
              </div>
              <div className={column}>
                <div
                  className={`${eyesContainer} ${
                    showFirstImage ? "d-block" : "d-none"
                  }`}
                >
                  <StaticImage
                    src={`../assets/TIE_EYE-CON_STRAIGHT.png`}
                    alt="eyes"
                    placeholder="none"
                    layout="constrained"
                    width={160}
                    height={160}
                    formats={["auto", "webp", "avif"]}
                  />
                </div>
                <div
                  className={`${eyesContainer} ${
                    !showFirstImage ? "d-block" : "d-none"
                  }`}
                >
                  <StaticImage
                    src={`../assets/TIE_EYE-CON_NORTHWEST.png`}
                    alt="eyes"
                    placeholder="none"
                    layout="constrained"
                    width={160}
                    height={160}
                    formats={["auto", "webp", "avif"]}
                  />
                </div>
              </div>
            </div>
          </Container>
        </section>

        <section
        id="jobs"
          className={`${"background-blue"} ${"text-sun-yellow"} ${sectionPadding}`}
        >
          <Container>
            <div className={`${flexGrid} ${orderReverse}`}>
              <div
                className={`${column} ${sectionText} ${containerPaddingLeft}`}
              >
                <h1 style={{ marginBottom: 16 }}>Jobs</h1>
                <p className="text-large">
                  Los, edler Jedi! Wir suchen kluge und weltoffene Köpfe in
                  Zürich und Münster.
                </p>
              </div>
              <div
                className={`${column} ${sectionText} ${containerPaddingRight} ${marginTopMobile}`}
              >
                {!!data.jobs?.nodes &&
                  data.jobs.nodes.map((node) => (
                    <div key={node.id} style={{ marginBottom: 16 }}>
                      <Link
                        to={`/jobs/${node.name}`}
                        className="text-sun-yellow text-larger"
                      >
                        {node.childMdx.frontmatter.title}&nbsp;&gt;
                      </Link>
                    </div>
                  ))}
              </div>
            </div>
          </Container>
        </section>

        <section
        id="news-und-events"
          className={`${"background-spring-green"} ${"text-purple"} ${sectionPadding}`}
        >
          <Container>
            <div className={`${flexGrid}`}>
              <div
                className={`${column} ${sectionText} ${containerPaddingRight}`}
              >
                <h1 style={{ marginBottom: 16 }}>
                  News und unsere aktuellen Events.
                </h1>
                <p className="text-large">
                  Neuste Produkte, wichtigste Fachmessen und unser Wissen stets
                  im Dienst von Forschung und Entwicklung.
                </p>
              </div>
              <div
                className={`${column} ${sectionText} ${containerPaddingLeft} ${marginTopMobile}`}
              >



{!!data.news?.nodes &&
                  data.news.nodes.map((node) => (
                    <div key={node.id} style={{ marginBottom: 16 }}>
                      <Link
                        to={`/news/${node.name}`}
                        className="text-purple text-larger"
                      >
                        {node.childMdx.frontmatter.title}&nbsp;&gt;
                      </Link>
                      <p>{node.childMdx.frontmatter.subtitle}</p>
                    </div>
                  ))}


{/*<div style={{ marginBottom: 16 }}>
                  <a
                    href="https://www.zhaw.ch/de/forschung/departementsuebergreifende-kooperationen/digital-health-lab/shift-smart-hospital-integrated-framework-tools-solutions/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-purple text-larger"
                  >
                    Wir sind Forschungspartner der ZHAW&nbsp;&gt;
                  </a>
                  <p>2022</p>
                </div>*/}

                
              </div>
            </div>
          </Container>
        </section>

        <section
          id="kontakt"
          className={`${"background-sky-blue"} ${"text-blue"} ${sectionPadding}`}
        >
          <Container>
            <div className={`${flexGrid} ${orderReverse}`}>
              <div
                className={`${column} ${sectionText} ${containerPaddingLeft}`}
              >
                <h1 style={{ marginBottom: 16 }}>
                  Wie können wir Ihnen weiter&shy;helfen?
                </h1>
                <p className="text-large">
                  Gerne kümmeren wir uns um Ihr Anliegen.
                </p>
              </div>
              <div
                className={`${column} ${sectionText} ${containerPaddingRight} ${marginTopMobile}`}
              >
                <ContactForm />
              </div>
            </div>
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export const query = graphql`
  {
    jobs: allFile(
      filter: { sourceInstanceName: { eq: "jobs" }, extension: { eq: "mdx" } }
      sort: { order: ASC, fields: childMdx___frontmatter___order }
    ) {
      nodes {
        id
        name
        childMdx {
          frontmatter {
            title
          }
        }
      }
    }
    news: allFile(
      filter: { sourceInstanceName: { eq: "news" }, extension: { eq: "mdx" } }
      sort: { order: ASC, fields: childMdx___frontmatter___order }
    ) {
      nodes {
        id
        name
        childMdx {
          frontmatter {
            title
            subtitle
          }
        }
      }
    }
  }
`;

export default TheIEngineersPage;
